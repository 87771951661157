@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quattrocento&family=Roboto:wght@400;700&display=swap');

.css-eg0mwd-MuiSlider-thumb {
  background: white !important;
  border: 3px solid #2f80ed !important;
  height: 15px !important;
  width: 15px !important;
}
.css-14pt78w-MuiSlider-rail {
  background-color: gray !important;
}
.css-1gv0vcd-MuiSlider-track {
  border: 1px solid #2f80ed !important;
}

.ReactModal__Overlay {
  z-index: 99999;
}

.ReactModal__Content {
  z-index: 9999;
  inset: 0px !important;
  padding: 0px !important;
  background: #000 !important;
  overflow: hidden !important;
}

.loader-wrapper {
  position: absolute;
  z-index: 9999999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  flex: 1;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 800px) {
  .loader-wrapper {
    padding-right: 350px !important;
  }
}

/* .svg-menu__path__seleccion,
.svg-menu__path__seleccion * {
  transition: all 0.25s ease-out;
} */

#Layer_1 {
  position: absolute;
}

.svg-menu__path__seleccion__text * {
  font-family: arial;
  text-transform: uppercase;
}

.svg-menu__path__seleccion {
  pointer-events: all;
}

.svg-menu__path__seleccion:active {
  pointer-events: none;
}

/* .svg-menu__path__seleccion:hover,
.svg-menu__path__seleccion.active {
  transform: scale(1.1);
} */

.svg-menu__path__seleccion__background * {
  fill: none;
}

.svg-menu__path__seleccion:hover .svg-menu__path__seleccion__background *,
.svg-menu__path__seleccion.active .svg-menu__path__seleccion__background *,
.svg-menu__path__seleccion .hovering {
  z-index: 99999999;
  fill: #14b1bb;
  fill-opacity: 0.5;
  stroke: #fff;
  stroke-width: 1px;
  stroke-linejoin: round;
}

.hover-warning
  .svg-menu__path__seleccion:hover
  .svg-menu__path__seleccion__background
  *:not([data-unit='perspective_ground']):not([data-unit='perspective-dot']),
.hover-warning
  .svg-menu__path__seleccion.active
  .svg-menu__path__seleccion__background
  *:not([data-unit='perspective_ground']):not([data-unit='perspective-dot']),
.hover-warning
  .svg-menu__path__seleccion
  .hovering:not([data-unit='perspective_ground']):not(
    [data-unit='perspective-dot']
  ) {
  fill: #bb7b14;
}

.hover-danger
  .svg-menu__path__seleccion:hover
  .svg-menu__path__seleccion__background
  *:not([data-unit='perspective_ground']):not([data-unit='perspective-dot']),
.hover-danger
  .svg-menu__path__seleccion.active
  .svg-menu__path__seleccion__background
  *:not([data-unit='perspective_ground']):not([data-unit='perspective-dot']),
.hover-danger
  .svg-menu__path__seleccion
  .hovering:not([data-unit='perspective_ground']):not(
    [data-unit='perspective-dot']
  ) {
  fill: #bb1414;
}
